import axios from "axios";
import { ProcesEncrypt } from '../util/anyFunctions'


const newHeader = (headers) =>{
    const tokenEncrypted = localStorage.getItem("_flat_data");
    if(tokenEncrypted){
        headers.authorization = `Bearer ${tokenEncrypted}`
    }
}

export const categoryList = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/category/list`, data, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return categoryList(data)
        }else{
            console.log("Error categoryList= ", error)
            return  errorResponse?.data
        }
    }
}

export const storesByCategoryList = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/store/list`, data, {headers: headers});
        res.data.data = JSON.parse((ProcesEncrypt(res.data.data,23)))
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return storesByCategoryList(data)
        }else{
            console.log("Error storesByCategoryList= ", error)
            return  errorResponse?.data
        }
    }
}

export const storesRandomByCategoryList = async () => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.get(`${process.env.REACT_APP_ROOT}/store/list/random`, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return storesRandomByCategoryList(data)
        }else{
            console.log("Error storesRandomByCategoryList= ", error)
            return  errorResponse?.data
        }
    }
}

export const getTags = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/tag/list`, data, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return getTags(data)
        }else{
            console.log("Error getTags= ", error)
            return  errorResponse?.data
        }
    }
}

export const favoritesStoresByCategoryList = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/store/favorite-list`, data, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return favoritesStoresByCategoryList(data)
        }else{
            console.log("Error favoritesStoresByCategoryList= ", error)
            return  errorResponse?.data
        }
    }
}

export const changeStoreFavoriteStatus = async ({storeId}) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.put(`${process.env.REACT_APP_ROOT}/store/${storeId}/set-favorite`, {}, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return changeStoreFavoriteStatus({storeId})
        }else{
            console.log("Error changeStoreFavoriteStatus= ", error)
            return  errorResponse?.data
        }
    }
}

export const setComment = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/store/${data.storeId}/set-comment`, data, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return setComment(data)
        }else{
            console.log("Error setComment= ", error)
            return  errorResponse?.data
        }
    }
}

export const storeById = async ({storeId}) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.get(`${process.env.REACT_APP_ROOT}/store/${storeId}`, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return storeById({storeId})
        }else{
            console.log("Error storeById= ", error)
            return  errorResponse?.data
        }
    }
}

export const login = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/user/login`, data, {headers: headers});
        return res.data;
    } catch (error) {
        console.log("Error login= ", error)
        return  error.response.data
    }
}

export const getUserPhoto = async () => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.get(`${process.env.REACT_APP_ROOT}/user/photo`, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return getUserPhoto()
        }else{
            console.log("Error getUserPhoto= ", error)
            return  errorResponse?.data
        }
    }
}

export const getPetTypes = async () => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);

    try {
        const res = await axios.get(`${process.env.REACT_APP_ROOT}/user/list-pet-types`, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return getPetTypes()
        }else{
            console.log("Error getPetTypes= ", error)
            return  errorResponse?.data
        }
    }
}

export const getProfile = async () => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);

    try {
        const res = await axios.get(`${process.env.REACT_APP_ROOT}/user/`, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return getProfile()
        }else{
            console.log("Error getProfile= ", error)
            return  errorResponse?.data
        }
    }
}

export const getMyServices = async ({page}) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);

    try {
        const res = await axios.get(`${process.env.REACT_APP_ROOT}/store/my-services?page=${page}`, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return getMyServices({page})
        }else{
            console.log("Error getMyServices= ", error)
            return  errorResponse?.data
        }
    }
}

export const updateProfile = async (data) => {
    let headers = {'Content-Type': 'multipart/form-data'}
    newHeader(headers);
    try {
        const res = await axios.patch(`${process.env.REACT_APP_ROOT}/user/`, data, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return updateProfile(data)
        }else{
            console.log("Error updateProfile= ", error)
            return  errorResponse?.data
        }
    }
}

export const saveService = async (data) => {
    let headers = {'Content-Type': 'multipart/form-data'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/store/`, data, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return saveService(data)
        }else{
            console.log("Error saveService= ", error)
            return  errorResponse?.data
        }
    }
}

export const tracking = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/tracking/set-record`, data, {headers: headers});
        return res.data;
    } catch (error) {
        console.log("Error saveService= ", error)
        return  errorResponse?.data
    }
}

export const internalTracking = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/tracking/set-record-internal`, data, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return internalTracking(data)
        }else{
            console.log("Error internalTracking= ", error)
            return  errorResponse?.data
        }
    }
}

export const saveFirubox = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/firubox/save`, data, {headers: headers});
        return res.data;
    } catch (error) {
        console.log("Error saveService= ", error)
        return  errorResponse?.data
    }
}

export const getPriceDelivery = async (data) => {
    let headers = {'Content-Type': 'application/json'}
    newHeader(headers);
    try {
        const res = await axios.post(`${process.env.REACT_APP_ROOT}/user/google/get-route`, data, {headers: headers});
        return res.data;
    } catch (error) {
        const errorResponse = error.response;
        if(errorResponse?.status === 401 && errorResponse?.data?.newToken){
            const tokenEncrypted = ProcesEncrypt(errorResponse?.data?.newToken, 3);
            localStorage.setItem("_flat_data", tokenEncrypted);
            return getPriceDelivery(data)
        }else{
            console.log("Error getPriceDelivery= ", error)
            return  errorResponse?.data
        }
    }
}